import React from 'react'

const CurveSVG = () => {
  return (
    <div className='SVG'>
        {/* <svg id="wave" style={{backgroundColor:"black",transform:"rotate(0deg)", transition: "0.3s"}} viewBox="0 0 1440 200" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stopColor="white" offset="0%"></stop></linearGradient></defs><path style={{transform:"translate(0, 0px)", opacity:"1"}} fill="url(#sw-gradient-0)" d="M0,80L48,90C96,100,192,120,288,130C384,140,480,140,576,116.7C672,93,768,47,864,30C960,13,1056,27,1152,53.3C1248,80,1344,120,1440,123.3C1536,127,1632,93,1728,90C1824,87,1920,113,2016,123.3C2112,133,2208,127,2304,116.7C2400,107,2496,93,2592,86.7C2688,80,2784,80,2880,83.3C2976,87,3072,93,3168,90C3264,87,3360,73,3456,76.7C3552,80,3648,100,3744,110C3840,120,3936,120,4032,113.3C4128,107,4224,93,4320,100C4416,107,4512,133,4608,120C4704,107,4800,53,4896,33.3C4992,13,5088,27,5184,30C5280,33,5376,27,5472,46.7C5568,67,5664,113,5760,123.3C5856,133,5952,107,6048,103.3C6144,100,6240,120,6336,116.7C6432,113,6528,87,6624,90C6720,93,6816,127,6864,143.3L6912,160L6912,200L6864,200C6816,200,6720,200,6624,200C6528,200,6432,200,6336,200C6240,200,6144,200,6048,200C5952,200,5856,200,5760,200C5664,200,5568,200,5472,200C5376,200,5280,200,5184,200C5088,200,4992,200,4896,200C4800,200,4704,200,4608,200C4512,200,4416,200,4320,200C4224,200,4128,200,4032,200C3936,200,3840,200,3744,200C3648,200,3552,200,3456,200C3360,200,3264,200,3168,200C3072,200,2976,200,2880,200C2784,200,2688,200,2592,200C2496,200,2400,200,2304,200C2208,200,2112,200,2016,200C1920,200,1824,200,1728,200C1632,200,1536,200,1440,200C1344,200,1248,200,1152,200C1056,200,960,200,864,200C768,200,672,200,576,200C480,200,384,200,288,200C192,200,96,200,48,200L0,200Z"></path></svg> */}

        <svg id="wave" style={{marginTop:"-50px",  backgroundColor:"black", transform:"rotate(0deg)", transition: "0.3s"}} viewBox="0 0 1440 280" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stopColor="white" offset="0%"></stop><stop stopColor="rgba(255, 255, 255, 1)" offset="100%"></stop></linearGradient></defs><path style={{transform:"translate(0, 0px)" ,  opacity:"1"}}fill="url(#sw-gradient-0)" d="M0,56L40,74.7C80,93,160,131,240,126C320,121,400,75,480,84C560,93,640,159,720,196C800,233,880,243,960,219.3C1040,196,1120,140,1200,102.7C1280,65,1360,47,1440,37.3C1520,28,1600,28,1680,42C1760,56,1840,84,1920,88.7C2000,93,2080,75,2160,84C2240,93,2320,131,2400,154C2480,177,2560,187,2640,177.3C2720,168,2800,140,2880,130.7C2960,121,3040,131,3120,121.3C3200,112,3280,84,3360,88.7C3440,93,3520,131,3600,163.3C3680,196,3760,224,3840,200.7C3920,177,4000,103,4080,79.3C4160,56,4240,84,4320,112C4400,140,4480,168,4560,168C4640,168,4720,140,4800,121.3C4880,103,4960,93,5040,88.7C5120,84,5200,84,5280,102.7C5360,121,5440,159,5520,186.7C5600,215,5680,233,5720,242.7L5760,252L5760,280L5720,280C5680,280,5600,280,5520,280C5440,280,5360,280,5280,280C5200,280,5120,280,5040,280C4960,280,4880,280,4800,280C4720,280,4640,280,4560,280C4480,280,4400,280,4320,280C4240,280,4160,280,4080,280C4000,280,3920,280,3840,280C3760,280,3680,280,3600,280C3520,280,3440,280,3360,280C3280,280,3200,280,3120,280C3040,280,2960,280,2880,280C2800,280,2720,280,2640,280C2560,280,2480,280,2400,280C2320,280,2240,280,2160,280C2080,280,2000,280,1920,280C1840,280,1760,280,1680,280C1600,280,1520,280,1440,280C1360,280,1280,280,1200,280C1120,280,1040,280,960,280C880,280,800,280,720,280C640,280,560,280,480,280C400,280,320,280,240,280C160,280,80,280,40,280L0,280Z"></path></svg>
    
    </div>
  )
}

export default CurveSVG