import React from "react";
import { NavLink } from "react-router-dom";
import CV from "../Assets/updated-CV.pdf"
const IntroButton = () => {
  const rsumePath = ""
  return (
    <div className="intro-btn">
      <NavLink to="/Portofolio/contact">
        <button data-aos="flip-left" className="hire-btn m-2">Hire Me</button>
      </NavLink>
      <a href={CV} download>
        <button data-aos="flip-right" className="resume-btn m-2">My Resume</button>
      </a>
    </div>
  );
};

export default IntroButton;
