import React, { useEffect, useRef } from "react";
import CoderImg from "../Assets/GalleryImg/coder-img.png"
const PhotoGallery = () => {
  return (
    <>
      <div className="coder-img">
        <img src={CoderImg} alt="Coder Img" />
      </div>
    </>
  );
};

export default PhotoGallery;
