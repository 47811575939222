import React from 'react'
import Omcoder from "../Assets/Images/om-coder.png"

const Footer = () => {
  return (
    <>
      <footer>
      <div className='footer text-white text-center'>
           &copy; Copyright 2024 &nbsp;&nbsp;|&nbsp;&nbsp; <img src={Omcoder} width={"100"} alt='om-Coder' />
          </div>
      </footer>
    </>
  )
}

export default Footer
